import { addStaycation } from '@services/krent.service'
import React, { useState, useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Terms from './terms'

// Type definitions
interface BookStaycationProps {
  btnText?: string
  hasPackage?: string
}

interface StaycationFormProps {
  onSubmitSuccess?: () => void
  onCancel?: () => void
  contactHeading?: boolean
  hasPackage?: string
}

type PackageType = 'bronze' | 'silver' | 'gold'

interface FormData {
  fullName: string
  email: string
  phone: string
  location: string
  package: string
  arrivalDate: string
  departureDate: string
  termsAccepted: boolean
}

interface FormErrors {
  fullName?: string
  email?: string
  phone?: string
  location?: string
  package?: string
  arrivalDate?: string
}

interface StaycationPayload {
  fullName: string
  email: string
  phone: string
  location: string
  package: string
  arrivalDate: string
  departureDate: string
}

const PACKAGE_DURATIONS: Record<PackageType, number> = {
  bronze: 3,
  silver: 5,
  gold: 7,
} as const

export const StaycationForm: React.FC<StaycationFormProps> = ({
  onSubmitSuccess,
  contactHeading = false,
  hasPackage,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    phone: '',
    location: '',
    package: '',
    arrivalDate: '',
    departureDate: '',
    termsAccepted: false,
  })
  const [termsModal, showTermsModal] = useState(false)

  const HOLIDAY_START = '2024-12-01'
  const HOLIDAY_END = '2025-01-05'
  const BLOCKED_START = '2024-12-01'
  const BLOCKED_END = '2024-12-09'

  const getMinDate = (): string => {
    const today = new Date()
    const minDate = new Date(HOLIDAY_START)
    return today > minDate ? today.toISOString().split('T')[0] : HOLIDAY_START
  }

  const isDateBlocked = (date: string): boolean => {
    const checkDate = new Date(date)
    const blockStart = new Date(BLOCKED_START)
    const blockEnd = new Date(BLOCKED_END)
    return checkDate >= blockStart && checkDate <= blockEnd
  }

  const isValidPackageType = (
    packageType: string,
  ): packageType is PackageType => {
    return ['bronze', 'silver', 'gold'].includes(packageType.toLowerCase())
  }

  const calculateDepartureDate = (
    arrivalDate: string,
    packageType: string,
  ): string => {
    if (!arrivalDate || !packageType) return ''

    const normalizedPackageType = packageType.toLowerCase() as PackageType
    if (!isValidPackageType(normalizedPackageType)) return ''

    const arrival = new Date(arrivalDate)
    const nights = PACKAGE_DURATIONS[normalizedPackageType]

    const departure = new Date(arrival)
    departure.setDate(arrival.getDate() + nights)

    const holidayEnd = new Date(HOLIDAY_END)
    if (departure > holidayEnd) return ''

    return departure.toISOString().split('T')[0]
  }

  useEffect(() => {
    const packageType = hasPackage || formData.package
    if (formData.arrivalDate && packageType) {
      const calculatedDepartureDate = calculateDepartureDate(
        formData.arrivalDate,
        packageType,
      )

      setFormData((prev) => ({
        ...prev,
        departureDate: calculatedDepartureDate,
      }))
    }
  }, [formData.arrivalDate, formData.package, hasPackage])

  const validateForm = (): boolean => {
    const errors: FormErrors = {}

    if (!formData.fullName.trim()) {
      errors.fullName = 'Full name is required'
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Please enter a valid email address'
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required'
    }

    if (!formData.location) {
      errors.location = 'Please select a location'
    }

    if (!hasPackage && !formData.package) {
      errors.package = 'Please select a package'
    }

    if (!formData.arrivalDate) {
      errors.arrivalDate = 'Check-in date is required'
    }

    if (formData.arrivalDate && isDateBlocked(formData.arrivalDate)) {
      errors.arrivalDate =
        'December 1-9 are fully booked. Please select different dates'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target

    if (name === 'arrivalDate') {
      if (isDateBlocked(value)) {
        toast.error(
          'December 1-9 are fully booked. Please select different dates',
        )
        return
      }

      const packageType = hasPackage || formData.package
      if (packageType) {
        const normalizedPackageType = packageType.toLowerCase()
        if (isValidPackageType(normalizedPackageType)) {
          const arrival = new Date(value)
          const nights = PACKAGE_DURATIONS[normalizedPackageType]
          const potentialDeparture = new Date(arrival)
          potentialDeparture.setDate(arrival.getDate() + nights)

          if (potentialDeparture > new Date(HOLIDAY_END)) {
            toast.error(
              `Selected date would exceed the holiday period end date for ${packageType} package`,
            )
            return
          }
        }
      }
    }

    if (formErrors[name as keyof FormErrors]) {
      setFormErrors((prev) => {
        const newErrors = { ...prev }
        delete newErrors[name as keyof FormErrors]
        return newErrors
      })
    }

    setFormData((prev) => ({
      ...prev,
      [name]:
        type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    if (!formData.termsAccepted) {
      setSubmitError('Please accept the terms and conditions')
      return
    }

    setIsSubmitting(true)
    setSubmitError(null)

    try {
      const payload: StaycationPayload = {
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        location: formData.location,
        package: hasPackage
          ? hasPackage.toLowerCase()
          : formData.package.toLowerCase(),
        arrivalDate: formData.arrivalDate,
        departureDate: formData.departureDate,
      }
      await addStaycation(payload)
      const currentUrl = window.location.href
      const urlWithParam =
        currentUrl + (currentUrl.includes('?') ? '&booked' : '?booked')

      window.history.replaceState(null, '', urlWithParam)
      toast.success('Staycation booked successfully!')
      showTermsModal(false)

      setFormData({
        fullName: '',
        email: '',
        phone: '',
        location: '',
        package: '',
        arrivalDate: '',
        departureDate: '',
        termsAccepted: false,
      })

      onSubmitSuccess?.()
    } catch (error) {
      setSubmitError('Failed to book staycation. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const isFormValid = (): any => {
    return (
      formData.fullName &&
      formData.email &&
      formData.phone &&
      formData.location &&
      (hasPackage || formData.package) &&
      formData.arrivalDate &&
      formData.termsAccepted &&
      !isDateBlocked(formData.arrivalDate) &&
      Object.keys(formErrors).length === 0
    )
  }

  const packageOptions = ['Bronze', 'Silver', 'Gold']
  const locationOptions = ['Lekki', 'VI', 'Ikoyi']

  const getPackageDurationText = (packageType: string): string => {
    const normalizedType = packageType.toLowerCase()
    if (!isValidPackageType(normalizedType)) return ''
    return `${PACKAGE_DURATIONS[normalizedType]} days`
  }

  return (
    <div>
      {submitError && (
        <div className="alert alert-danger" role="alert">
          {submitError}
        </div>
      )}

      <div className="py-4 px-4">
        {contactHeading && (
          <div className="mb-3">
            <p className="text-primary">Begin your December experience</p>
            <h4>Book a staycation</h4>
            <p>Fill out the form on this page to book stay</p>
          </div>
        )}

        <Row className="">
          <Col xs={12} md={6} className="mb-3">
            <label htmlFor="fullName" className="form-label fw-bold">
              Full name
            </label>
            <input
              type="text"
              className={`form-control ${
                formErrors.fullName ? 'is-invalid' : ''
              }`}
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
            {formErrors.fullName && (
              <div className="invalid-feedback">{formErrors.fullName}</div>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-3">
            <label htmlFor="email" className="form-label fw-bold">
              Email
            </label>
            <input
              type="email"
              className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {formErrors.email && (
              <div className="invalid-feedback">{formErrors.email}</div>
            )}
          </Col>
        </Row>

        <div className="mb-3 w-full">
          <label htmlFor="phone" className="form-label fw-bold">
            Phone number
          </label>
          <input
            type="tel"
            className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          {formErrors.phone && (
            <div className="invalid-feedback">{formErrors.phone}</div>
          )}
        </div>

        <div className="mb-3 w-full">
          <label htmlFor="location" className="form-label fw-bold">
            Select location
          </label>
          <select
            className={`form-control ${
              formErrors.location ? 'is-invalid' : ''
            }`}
            id="location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            required
          >
            <option value="">Select a location</option>
            {locationOptions.map((loc) => (
              <option key={loc} value={loc}>
                {loc}
              </option>
            ))}
          </select>
          {formErrors.location && (
            <div className="invalid-feedback">{formErrors.location}</div>
          )}
        </div>

        {!hasPackage && (
          <div className="mb-3 w-full">
            <label htmlFor="package" className="form-label fw-bold">
              Select package
            </label>
            <select
              className={`form-control ${
                formErrors.package ? 'is-invalid' : ''
              }`}
              id="package"
              name="package"
              value={formData.package}
              onChange={handleInputChange}
              required
            >
              <option value="">Choose a package</option>
              {packageOptions.map((pkg) => (
                <option key={pkg} value={pkg}>
                  {pkg} Package ({getPackageDurationText(pkg)})
                </option>
              ))}
            </select>
            {formErrors.package && (
              <div className="invalid-feedback">{formErrors.package}</div>
            )}
          </div>
        )}

        <Row className="mb-4">
          <Col xs={12} md={6} className="mb-3">
            <label htmlFor="arrivalDate" className="form-label fw-bold">
              Check-in
            </label>
            <input
              type="date"
              className={`form-control ${
                formErrors.arrivalDate ? 'is-invalid' : ''
              }`}
              id="arrivalDate"
              name="arrivalDate"
              value={formData.arrivalDate}
              onChange={handleInputChange}
              min={getMinDate()}
              max={HOLIDAY_END}
              required
              onKeyDown={(e) => e.preventDefault()}
              style={{
                backgroundColor: isDateBlocked(formData.arrivalDate)
                  ? '#ffebee'
                  : 'inherit',
              }}
            />
            {formErrors.arrivalDate && (
              <div className="invalid-feedback">{formErrors.arrivalDate}</div>
            )}
          </Col>

          <Col xs={12} md={6} className="mb-3">
            <label htmlFor="departureDate" className="form-label fw-bold">
              Check-out
            </label>
            <input
              type="date"
              className="form-control bg-light"
              id="departureDate"
              name="departureDate"
              value={formData.departureDate}
              onChange={handleInputChange}
              required
              readOnly
              disabled
            />
          </Col>
        </Row>

        <div className="my-3 d-flex align-items-center">
          <input
            type="checkbox"
            id="termsAccepted"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleInputChange}
            className="me-2"
            required
          />
          <label htmlFor="termsAccepted">
            By clicking, it means that you have agreed to{' '}
            <a href="/staycation-terms" className="text-primary underline">
              Krent's Terms and Conditions
            </a>
          </label>
        </div>

        <div className="d-flex gap-3">
          <button
            onClick={() => showTermsModal(true)}
            className="bg-primary w-full rounded-10 py-2 text-white fw-bold mt-3"
            disabled={!isFormValid()}
          >
            Submit
          </button>
        </div>
      </div>
      <Modal
        show={termsModal}
        onClose={() => showTermsModal(false)}
        dismissible={false}
        size="lg"
      >
        <div className="p-4">
          <div className="d-flex justify-between">
            <p></p>
            <button
              onClick={() => showTermsModal(false)}
              className="bg-white mr-auto"
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>
          <Terms />
          <button
            onClick={handleSubmit}
            type="submit"
            className="bg-primary w-full rounded-10 py-2 text-white fw-bold mt-3"
            disabled={!isFormValid() || isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </Modal>
    </div>
  )
}

export const BookStaycation = ({
  btnText,
  hasPackage,
}: BookStaycationProps) => {
  const [showBookForm, setShowBookForm] = useState(false)

  return (
    <div>
      <button
        onClick={() => {
          const currentUrl = window.location.href
          const urlWithoutBooked = currentUrl.replace(
            /[&?]booked(=[^&]*)?/g,
            '',
          )

          if (currentUrl !== urlWithoutBooked) {
            window.history.replaceState(null, '', urlWithoutBooked)
          }

          setShowBookForm(true)
        }}
        className="bg-primary px-4 py-2 rounded-10 fw-bold text-white fs-14 d-flex items-center gap-2"
      >
        {btnText || 'Book Now'}&nbsp;
        <i className="fa-regular fa-arrow-up-right"></i>
      </button>

      <Modal
        show={showBookForm}
        onHide={() => setShowBookForm(false)}
        centered
        size="lg"
      >
        <div className="p-4">
          <div className="d-flex justify-between">
            <h4>Book a staycation package</h4>
            <button onClick={() => setShowBookForm(false)} className="bg-white">
              <i className="fa-regular fa-x"></i>
            </button>
          </div>

          <StaycationForm
            onSubmitSuccess={() => setShowBookForm(false)}
            hasPackage={hasPackage}
          />
        </div>
      </Modal>
    </div>
  )
}
