import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, NavLink} from 'react-router-dom';
import Logo from '@shared/Logo';
// import { getProfile } from '@services/krent.service';
import {useSessionStorage} from '../../../hooks';
// import Marquee from 'react-fast-marquee';

type BaseNavBarProps = Partial<{
  showBanner?: boolean;
  bannerMessage?: string;
  news?: any;
}>;

export const BaseNavBar: React.FC<BaseNavBarProps> = (
  {
    // showBanner,
    // bannerMessage,
    // news,
  }
) => {
  const [user] = useSessionStorage('user', {});
  useEffect(() => {
    // const abortController = new AbortController();
    // if (user) {
    //   getProfile(abortController.signal).then((res: any) => {
    //     setUser(res.data);
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className='fixed-top'>
      {/* {showBanner && (
        <div className='bg-primary py-2 text-center'>
          <div className='mb-0 fs-12 text-white'>
            <span className='fw-bolder'>News &amp; Updates</span>{' '}
            <Marquee pauseOnHover>
              {news?.map((item: any) => {
                return (
                  <p key={item.id} className='mr-4'>
                    <a
                      href={item?.link}
                      target='_blank'
                      rel='noreferrer'
                      className='text-white'
                    >
                      {item?.title.rendered.slice(0, 55)}...
                      <span style={{color: '#FFA500'}}>learn more</span>
                    </a>
                  </p>
                );
              })}
            </Marquee>
          </div>
        </div>
      )} */}
      <nav className='navbar navbar-expand-lg bg-white'>
        <div className='container py-2 py-md-0'>
          <div className='d-flex flex-row justify-content-between align-items-center col-12 col-lg-auto'>
            <div>
              <Link className='navbar-brand fw-bolder align-middle mt-1' to='/'>
                <Logo />
              </Link>
            </div>
            <div className='d-flex align-items-center'>
              {!user.id && (
                <Link
                  className='btn btn-custom-primary text-decoration-none fs-12 d-lg-none fw-600 py-2 px-3 rounded-pill w-100 w-md-25 mb-1'
                  to='/get-started/'
                >
                  Get Started
                </Link>
              )}
              <button
                className='navbar-toggler border-0 align-self-start'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarNavAltMarkup'
                aria-controls='navbarNavAltMarkup'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
            </div>
          </div>

          <div
            className='collapse navbar-collapse justify-content-end fs-14 fw-600'
            id='navbarNavAltMarkup'
          >
            <div className='navbar-nav me-4'>
              <NavLink
                className='nav-link py-3 px-4'
                aria-current='page'
                to='/'
              >
                Home
              </NavLink>
              <NavLink className='nav-link py-3 px-4' to='/All Properties/'>
                For Sale
              </NavLink>
              <NavLink className='nav-link py-3 px-4' to='//'>
                For Rent
              </NavLink>
              <NavLink className='nav-link py-3 px-4' to='//'>
                Short Let
              </NavLink>
              {/* <NavLink
                className='nav-link py-3 px-4'
                to={{
                  pathname: "/sign-in/",
                  search: "listing/iBuying&utm_source=navbar",
                }}
              >
                Instant Buying
              </NavLink> */}
              <a
                href='https://blog.krent.space/'
                target='_blank'
                rel='noreferrer'
                className='nav-link py-3 px-4'
              >
                Krent Blog
              </a>
              <NavLink className='nav-link py-3 px-4 d-lg-none' to='/sign-in/'>
                Sign In
              </NavLink>
              {/* <a
                className='d-lg-none d-flex me-auto bg-success rounded-circle  align-items-center justify-content-center social-icon2 ml-3 mb-2'
                href='https://wa.me/+2349118008008'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fa-brands fa-whatsapp fs-22 text-white'></i>
              </a> */}
              {!user.id && (
                <NavLink
                  className='btn btn-custom-primary text-decoration-none fs-12 d-lg-none fw-600 py-3 px-4 rounded-pill w-100 w-md-25 mb-1'
                  to='/get-started/'
                >
                  Get Started
                </NavLink>
              )}
            </div>
          </div>
          <div className='navbar-nav ms-4'>
            <Link
              className='nav-link fw-bold me-3 py-2 d-none fs-14 d-lg-inline-block'
              to='/sign-in/'
            >
              {user.id ? 'My Profile' : 'Sign In'}
            </Link>

            {!user.id && (
              <Link
                className='btn btn-custom-primary d-none d-lg-inline-block fs-12 fw-600 py-2 px-4 rounded-pill'
                to='/get-started/'
              >
                Get Started
              </Link>
            )}
          </div>
          {/* <a
            className='d-none d-lg-flex me-auto bg-success rounded-circle  align-items-center justify-content-center social-icon2 ml-2'
            href='https://wa.me/+2349118008008'
            target='_blank'
            rel='noreferrer'
          >
            <i className='fa-brands fa-whatsapp fs-22 text-white'></i>
          </a> */}
        </div>
      </nav>
      {/* search */}
    </header>
  );
};

BaseNavBar.propTypes = {
  showBanner: PropTypes.bool,
  bannerMessage: PropTypes.string,
};

BaseNavBar.defaultProps = {
  showBanner: true,
  bannerMessage: `Krent.space goes live on beta 🎉`,
};
