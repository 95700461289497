import { Routes, Route } from 'react-router-dom'
import App from 'App'
import {
  Explore,
  Landing2,
  Property,
  SignIn,
  AccountSelection,
  SignUp,
  VerifyEmail,
  ResetPassword,
  ChangePassword,
  ChangePasswordSuccess,
  Blank,
  Overview,
  Wallet,
  Listing,
  ListProperty,
  Profile,
  Requests,
  Messaging,
  Invoice,
  MyProperties,
  RedirectPage,
  Faq,
  TermsAndCondition,
  Privacy,
  Contact,
  AcceptTransaction,
  PaymentSuccessPage,
  About,
  ReverifyEmail,
  PropertiesPage,
} from '../pages/index'

import { Settings } from '@pages/Settings'

import { PrivateRoutes } from './PrivateRoutes'
import { SessionRoutes } from './SessionRoutes'
import { EditProfile } from '@pages/Profile/Edit'
import PickAdate from '@pages/Requests/PickAdate'
import Preview from '@pages/MyProperties/Preview'
import NotFoundPage from '@pages/404/NotFoundPage'
import { MyBookings } from '@pages/myBookings'
import Staycation from '@pages/Staycation'
import Packages from '@pages/Staycation/packages'
import StaycationContact from '@pages/Staycation/contact'
import TAndC from '@pages/Staycation/tandc'
// import DevelopersDashboard from '@pages/Developers/Dashboard';
// import Projects from '@pages/Developers/Projects';
// import ProjectDetails from '@pages/Developers/Projects/project-details';
// import ProjectForm from '@pages/Developers/Projects/project-form';
// import DevelopersProfile from '@pages/Developers/Profile';
// import EditDeveloperProfile from '@pages/Developers/Profile/edit-profile';
// import ProjectProperty from '@pages/Developers/Projects/Property/project-property';
// import { DevelopersSettings } from '@pages/Developers/Settings';
// import DevelopersSales from '@pages/Developers/Sales';
// import AddProperty from '@pages/Developers/Projects/add-property';
// import { DevMessaging } from '@pages/Developers/Forum';
// import AddNewForum from '@pages/Developers/Forum/new-forum';
// import DevInterestDetails from '@pages/Developers/Sales/dev-interest-details';
// import Purchases from '@pages/Purchases';
// import UserInterestPropertyDetails from '@pages/Client/InterstPropertyDetails';
// import UserPurchasedPropertyDetails from '@pages/Client/PurchasePropertyDetails';
// import UsersDashboard from '@pages/UsersDashboard';

// import {useEffect} from 'react';

const AppRouter = () => {
  // useEffect(() => {
  //   const head = document.head;
  //   const title = document.getElementsByTagName('title')[0];
  //   const metaDescription = document.querySelector('meta[name="description"]');

  //   if (title) {
  //     head.insertBefore(title, head.firstChild);
  //   }

  //   if (metaDescription) {
  //     head.insertBefore(metaDescription, head.firstChild);
  //   }
  // }, [document.head, document.title]);
  return (
    <Routes>
      <Route path="/" element={<Landing2 />} />
      <Route path="/staycation" element={<Staycation />} />
      <Route path="/staycation-packages" element={<Packages />} />
      <Route path="/staycation-contact" element={<StaycationContact />} />
      <Route path="/staycation-terms" element={<TAndC />} />
      <Route path="/blank" element={<Blank />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/property/:id" element={<Property />} />
      <Route path="/properties" element={<Explore />} />
      <Route path="/properties/:id" element={<Preview />} />
      <Route path="/app" element={<App />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms" element={<TermsAndCondition />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/property-for-:type" element={<PropertiesPage />} />
      <Route path="/property-for-:type/:kind" element={<PropertiesPage />} />
      {/* <Route path="/developer-listing" element={<DeveloperAccount />} />
      <Route
        path="/developer-listing-projects/:id"
        element={<DeveloperAccountProjects />}
      />
      <Route
        path="/developer-listing-project-property/:id"
        element={<DevAccountProjectProperty />}
      />
       <Route
        path="/dev-profile"
        element={<ViewDeveloperProfile />}
      /> */}

      {/* <Route
        path='/property-for-:type/kind/:kind'
        element={<PropertiesPage />}
      /> */}
      <Route
        path="/property-for-:type/bedroom/:bedroom"
        element={<PropertiesPage />}
      />
      <Route
        path="/property-for-:type/in/:location"
        element={<PropertiesPage />}
      />
      <Route
        path="/property-for-:type/:kind/in/:location"
        element={<PropertiesPage />}
      />
      <Route path="/property-for-:type/in" element={<PropertiesPage />} />

      <Route
        path="/property-for-shortlet/in/:location"
        element={<PropertiesPage />}
      />
      <Route element={<SessionRoutes />}>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/reverify-email" element={<ReverifyEmail />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route
          path="/change-password/success"
          element={<ChangePasswordSuccess />}
        />
        <Route path="/get-started" element={<AccountSelection />} />
        <Route path="/get-started/:type" element={<SignUp />} />
        {/* <Route
          path="/get-started/developer-upload"
          element={<DeveloperUpload />}
        /> */}
        <Route path="/redirect" element={<RedirectPage />} />
        <Route path="/me/verify-account" element={<VerifyEmail />} />
        <Route path="/account-selection" element={<AccountSelection />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit/" element={<EditProfile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/messaging" element={<Messaging />} />
        <Route path="/message/:id" element={<Messaging />} />
        <Route path="/profile/transactions/form-ext" element={<Messaging />} />
        <Route
          path="/transaction/:id/availabilty"
          element={<AcceptTransaction />}
        />
        <Route path="/transaction/:id/pick-a-date" element={<PickAdate />} />
      </Route>

      <Route element={<PrivateRoutes roleOnly="landlord" />}>
        <Route path="/overview" element={<Overview />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/listings" element={<Listing />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/listings/:type" element={<ListProperty />} />
      </Route>
      <Route element={<PrivateRoutes roleOnly="agent" />}>
        <Route path="/overview" element={<Overview />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/listings" element={<Listing />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/listings/:type" element={<ListProperty />} />
      </Route>
      <Route element={<PrivateRoutes roleOnly="user" />}>
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/my-bookings" element={<MyBookings />} />
        <Route path="/explore-listings" element={<Explore />} />
        <Route path="/my-properties" element={<MyProperties />} />
        <Route path="/transaction/success" element={<PaymentSuccessPage />} />
        <Route
          path="/transaction/confirmation"
          element={<PaymentSuccessPage />}
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
export default AppRouter
