import React from 'react'

const Terms = () => {
  return (
    <div className="w-80 mx-auto">
    <div className="text-center">
      <h4 className="text-primary">
        TERMS AND CONDITIONS FOR STAYCATION BOOKINGS
      </h4>
      <p>
        By deciding to book and stay in any of our apartments, you agree
        to accept our terms and conditions stated below.
      </p>
    </div>

    <div>
      <h6 className="text-primary my-2">CHECK-IN/CHECK-OUT TIMES</h6>
      <ul className="sc-list-unstyled fw-bold">
        {[
          'Check-in: 2:00 PM',
          'Check-out: 11:00 AM',
          'Late check-out requests may incur additional fees.',
        ].map((amenity, index) => (
          <li key={index} className="mb-2">
            {amenity}
          </li>
        ))}
      </ul>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">PAYMENT</h6>
      <ul className="sc-list-unstyled fw-bold">
        {[
          'A full payment of the total booking cost is required to confirm your reservation.',
        ].map((amenity, index) => (
          <li key={index} className="mb-2">
            {amenity}
          </li>
        ))}
      </ul>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">OCCUPANCY</h6>
      <ul className="sc-list-unstyled fw-bold">
        {[
          'Maximum overnight guests: 10 guests.',
          'Specific experience limits:',
        ].map((amenity, index) => (
          <li key={index} className="mb-2">
            {amenity}
          </li>
        ))}
      </ul>
      <p>1. Dine with a chef: 10 guests.</p>
      <p>2. Sip and paint: 30 guests.</p>
      <p>3. Silent Disco + BBQ: 30 guests.</p>
      <p>4. Games Night: 30 guests.</p>
      <p>
        If damage or losses exceed the caution fee, additional charges may
        apply.
      </p>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">REFUNDABLE CAUTION FEE</h6>
      <ul className="sc-list-unstyled fw-bold">
        {[
          'A refundable caution fee of ₦2 million is required per stay.',
          'The fee will be refunded within 24 hours of checkout, provided the following conditions are met:',
        ].map((amenity, index) => (
          <li key={index} className="mb-2">
            {amenity}
          </li>
        ))}
      </ul>
      <p>1. No damage to the property or its contents.</p>
      <p>2. No loss of any items or fixtures.</p>
      <p>3. Maximum occupancy limits were not- exceeded.</p>
      <p>4. Checkout was completed on time or as previously agreed.</p>
      <p>
        If damage or losses exceed the caution fee, additional charges may
        apply.
      </p>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">
        CANCELLATIONS AND ALTERATION REQUESTS
      </h6>
      <p>
        Payments made for confirmed bookings are non-refundable. Guests
        cannot cancel or alter their bookings once confirmed.
      </p>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">PROPERTY DAMAGE</h6>
      <p>
        Guests are responsible for any damages caused to the property or
        its contents due to negligence, willful default, or irresponsible
        behavior.
      </p>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">USE OF PROPERTY</h6>
      <ul className="sc-list-unstyled">
        {[
          'The apartment may not be used for business or commercial activities, without prior consent from management.',
          'The Guest may not re-let/sublet the apartment to any other third party.',
        ].map((amenity, index) => (
          <li key={index} className="mb-2">
            {amenity}
          </li>
        ))}
      </ul>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">LIABILITY DISCLAIMER</h6>
      <ul className="sc-list-unstyled">
        {[
          'Krent App Limited is not liable for any loss or damage, including but not limited to personal injury, property damage, financial loss, or unforeseen circumstances that occur during your stay. Guests are encouraged to exercise caution and ensure personal safety.',
        ].map((amenity, index) => (
          <li key={index} className="mb-2">
            {amenity}
          </li>
        ))}
      </ul>
    </div>
    <div className="my-3">
      <h6 className="text-primary my-2">PARTY GUIDELINES</h6>
      <ul className="sc-list-unstyled">
        {[
          'Parties are permitted but must adhere to the stated guest limits and noise control rules. Guests assume full responsibility for the behavior and actions of their attendees.',
        ].map((amenity, index) => (
          <li key={index} className="mb-2">
            {amenity}
          </li>
        ))}
      </ul>
    </div>
    
    <p className="my-3">
      This is a list of general policies upheld by Krent. Should you
      require any clarification on terms not stated here, please contact
      us <span className="text-primary">hello@krent.space</span> or call
      09118008008
    </p>
  </div>
  )
}

export default Terms