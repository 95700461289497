import React from 'react'
import bgImg from '../../assets/images/staycation/bg-hero.png'
import sLogo from '../../assets/images/staycation/s-logo.png'
import images from '../../assets/images/staycation/imgs.png'
import img1 from '../../assets/images/staycation/img1.png'
import img2 from '../../assets/images/staycation/img2.png'
import img3 from '../../assets/images/staycation/img3.png'
import img4 from '../../assets/images/staycation/img4.png'
import img5 from '../../assets/images/staycation/img5.png'
import img6 from '../../assets/images/staycation/img6.png'
import img7 from '../../assets/images/staycation/img7.png'
import img8 from '../../assets/images/staycation/img8.png'
import img9 from '../../assets/images/staycation/img9.png'
import gal1 from '../../assets/images/staycation/gal1.png'
import gal2 from '../../assets/images/staycation/gal2.png'
import gal3 from '../../assets/images/staycation/gal3.png'
import gal4 from '../../assets/images/staycation/gal4.png'
import gal5 from '../../assets/images/staycation/gal5.png'
import gal6 from '../../assets/images/staycation/gal6.png'
import gal7 from '../../assets/images/staycation/gal7.png'
import gal8 from '../../assets/images/staycation/gal8.png'
import gal9 from '../../assets/images/staycation/gal9.png'
import gal10 from '../../assets/images/staycation/gal10.png'
import gal11 from '../../assets/images/staycation/gal11.png'
import gal12 from '../../assets/images/staycation/gal12.png'
import scroll1 from '../../assets/images/staycation/scroll1.png'
import scroll2 from '../../assets/images/staycation/scroll2.png'
import scroll3 from '../../assets/images/staycation/scroll3.png'
import scroll4 from '../../assets/images/staycation/scroll4.png'
import scroll5 from '../../assets/images/staycation/scroll5.png'

// import formBg from '../../assets/images/staycation/form-modal.png'

import { ToastContainer } from 'react-toastify'
import StaycationFooter from 'components/Staycation/staycation-footer'
import WhatsappBtn from './whatsapp-btn'
import { Col, Container, Row } from 'react-bootstrap'
import { SpecialPackages } from './special-pkgs'

const scrollImages = [
  { src: scroll1, alt: 'Krent image 1' },
  { src: scroll2, alt: 'Krent image 2' },
  { src: scroll3, alt: 'Krent image 3' },
  { src: scroll4, alt: 'Krent image 4' },
  { src: scroll5, alt: 'Krent image 5' },
]
const galleryImages = [
  { src: gal1, alt: 'Krent image 1' },
  { src: gal2, alt: 'Krent image 2' },
  { src: gal3, alt: 'Krent image 3' },
  { src: gal4, alt: 'Krent image 4' },
  { src: gal5, alt: 'Krent image 5' },
  { src: gal6, alt: 'Krent image 6' },
  { src: gal7, alt: 'Krent image 7' },
  { src: gal8, alt: 'Krent image 8' },
  { src: gal9, alt: 'Krent image 9' },
  { src: gal10, alt: 'Krent image 10' },
  { src: gal11, alt: 'Krent image 11' },
  { src: gal12, alt: 'Krent image 12' },
]

const Staycation = () => {
  return (
    <div className="text-justify">
      <ToastContainer limit={1} />

      <section className="">
        <div className="relative">
          <div
            className=" inset-0 w-full staycation-bg"
            style={{
              backgroundImage: ` url(${bgImg})`,
            }}
          >
            <div className="absolute inset-0 bg-black/30" />

            <div
              className="flex relative align-items-center justify-content-center mx-auto hero-head"
              style={{ backgroundColor: '#c1a536', top: '1.6rem' }}
            >
              <div className="relative">
                <h4 className="text-white ">
                  HOLIDAY BLISS STAYCATION @ YULETIDE GETAWAY
                </h4>
                <img src={sLogo} loading="lazy" alt="krent logo" />
              </div>
            </div>
            <div className="relative z-10 h-70 flex items-center justify-center hero-content">
              <div className=" text-center staycation-hero ">
                <h1 className=" mb-2 font-display">
                  ESCAPE TO PARADISE: INDULGE IN PURE LUXURY WITH YOUR DREAM
                  HOLIDAY STAYCATION
                </h1>
                <h2 className=" fw-500 ">
                  Treat yourself to the perfect holiday-escape where bliss,
                  relaxation, and unforgettable memories await.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="w-90 mx-auto py-4">
        <section className="py-12 my-4 z-20">
          <div>
            <h3 className="xmas-text ">The Holiday Bells are Ringing…</h3>
          </div>
        </section>

        <section className="my-4 pt-4">
          <div className="py-4">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <div className="mb-4">
                  <h5 className="fs-24">
                    And we know what that means for you:
                  </h5>
                  <div className="fs-16 py-3 fw-bold">
                    <p>Time to rest. </p>
                    <p>Time to pamper yourself.</p>
                    <p>Time to fulfill promises.</p>
                    <p>And, most importantly,</p>
                    <p>
                      time to see those smiles on the faces of your loved ones.
                    </p>
                    <p>But here's the truth—you've earned this moment.</p>
                    <p>
                      And we have just what you need to make it unforgettable.
                    </p>
                    <p>You've worked hard all year long.</p>
                    <p>
                      Now, it's time to unwind, recharge, and experience
                      something truly special.
                    </p>
                    <p>This holiday, you won't need to lift a finger.</p>
                    <p>
                      Because you deserve a staycation where everything is taken
                      care of.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={images}
                  alt="krent images"
                  className="img-fluid rounded"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="mb-4">
              <h5 className="fs-24">Imagine…</h5>
              <div className="fs-16 py-3 fw-bold">
                <p>
                  A holiday where you don't have to look at the same old space
                  you've been seeing all year long. A holiday where the view,
                  the luxury, and the comfort are new, fresh, and all for you.{' '}
                </p>
                <p>
                  A holiday where you don't have to eat the same Christmas meal
                  you've had for years.{' '}
                </p>
                <p>
                  Here, it's about elevating the experience, where every detail
                  is designed to delight.
                </p>
                <p>
                  {' '}
                  A holiday where you can truly be free—to do anything, anytime,
                  any way you want.
                </p>
              </div>
            </div>
            <div className="mb-4">
              <h5 className="fs-24">
                No rules. No limits. Just pure indulgence.
              </h5>
              <div className="fs-16 pt-3 fw-bold">
                <p>
                  A holiday where you can be lazy, guilt-free, while still
                  creating memories so beautiful that you'll cherish them
                  forever.
                </p>
                <p>
                  This holiday isn't just bliss—it's the definition of bliss.
                </p>
                <p>At the Yuletide Getaway, this is exactly what you'll get.</p>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img1}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img2}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img3}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
          </div>
          <div className="pt-4 pb-2">
            <div className="mb-2 pt-4">
              <h5 className="fs-24">A Holiday Bliss Staycation</h5>
              <div className="fs-16 py-3 fw-bold">
                <p>
                  that wraps you in luxury and relaxation, all while pampering
                  you and your loved ones to the core.
                </p>
                <p>But here's the catch—this is a limited opportunity.</p>
                <p>Spots are filling up quickly.</p>
                <p>
                  {' '}
                  A holiday where you can truly be free—to do anything, anytime,
                  any way you want.
                </p>
              </div>
            </div>
            <div className="mb-2">
              <h5 className="fs-24">Now, imagine this...</h5>
              <div className="fs-16 py-3 fw-bold">
                <p>
                  You're not just unwinding in luxury—you're turning up the fun
                  with a Silent Disco Party that's unlike anything you've ever
                  experienced.
                </p>
              </div>
            </div>
            <div className="mb-2">
              <h5 className="fs-24">Picture this: </h5>
              <div className="fs-16 pt-3 fw-bold">
                <p>
                  Everyone's wearing their headphones, dancing to their favorite
                  beats, and the atmosphere is electric.
                </p>
                <p>
                  Add to that a sizzling BBQ, with perfectly grilled meats and
                  sides— this setup can host up to 30 guests, making it the
                  perfect way to bond with friends and family while creating
                  memories that will last a lifetime
                </p>
              </div>
            </div>
          </div>
          <WhatsappBtn />
          <div className="row g-3 pt-3">
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img4}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img5}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img6}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
          </div>
          <div className="py-4">
            <div className="mb-2">
              <h5 className="fs-24">Want more?</h5>
              <div className="fs-16 py-3 fw-bold">
                <p>
                  How about a Games Night? Gather everyone for an unforgettable
                  evening filled with laughter and competition.
                </p>
                <p>
                  Whether it's a round of Jenga, classic card games, or intense
                  PS5 sessions, there's something for everyone to enjoy.{' '}
                </p>
                <p>
                  With room for up to 30 guests, this night promises non-stop
                  fun for your group, big or small.
                </p>
              </div>
            </div>
          </div>
          <Container fluid>
            <div
              className="d-flex overflow-x-auto gap-3 pb-3"
              style={{
                flexWrap: 'nowrap',
                WebkitOverflowScrolling: 'touch',
              }}
            >
              {scrollImages.map((image, index) => (
                <div
                  key={index}
                  className="flex-shrink-0"
                  style={{ width: '300px' }}
                >
                  <img
                    loading="lazy"
                    src={image.src}
                    alt={image.alt}
                    className="img-fluid rounded w-100 mb-3"
                  />
                </div>
              ))}
            </div>
          </Container>
          <div className="py-2">
            <h3>
              And, because we believe in giving you the ultimate pampered
              experience,
            </h3>
            <div className="mb-2">
              <h5 className="fs-20 py-3">Here are the Bonuses Included:</h5>
              <div className="fs-16 pt-1">
                <p className="mb-3">
                  <span className="fw-bold">Free Breakfast: </span>Wake up to
                  freshly prepared meals, making sure your day starts off right.
                </p>
                <p className="mb-3">
                  <span className="fw-bold">Premium Concierge Services:</span>{' '}
                  Need transportation? Enjoy complimentary pickup and drop-off
                  services from anywhere in Lagos. We've got your convenience
                  covered.
                </p>
                <p className="mb-3">
                  <span className="fw-bold">Booking Assistance:</span> From
                  concerts to beach parties, let us handle your bookings for the
                  best Lagos outings, so you can simply show up and enjoy.
                </p>
              </div>
            </div>
            <div className="mb-2">
              <h5 className="fs-24"> Want even more?</h5>
              <div className="fs-16 py-3">
                <p>
                  For an extra touch of luxury, we offer optional extras like a
                  Police Escort for safe travel around Lagos, or Alcohol
                  Sourcing Assistance for your favorite drinks delivered at
                  wholesale prices right to your doorstep.
                </p>
                <p className="fw-bold mt-3">
                  This isn't just a staycation—it's an experience, a dream you
                  can walk right into.
                </p>
              </div>
            </div>
            <div className="">
              <h5 className="fs-24">
                With a Holiday Bliss Staycation, you get:
              </h5>
              <div className="fs-16 py-3">
                <p className="mb-3">
                  <span className="fw-bold">Overnight Stay:</span> Comfortably
                  accommodating 8-10 guests.
                </p>
                <p className="mb-3">
                  <span className="fw-bold">Silent Disco:</span> Perfect for up
                  to 30 guests.
                </p>
                <p>
                  <span className="fw-bold">Games Night:</span> Also great for
                  groups of up to 30 guests. With our different packages
                  starting at N3.75m for 3 nights of pure luxury, bliss, and
                  unforgettable memories.
                </p>
              </div>
            </div>
          </div>
          <WhatsappBtn />
          <div className="row g-3 my-4">
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img7}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img8}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
            <div className="col-12 col-lg-4">
              <img
                loading="lazy"
                src={img9}
                alt="krent images"
                className="img-fluid rounded w-100"
              />
            </div>
          </div>
          <div className="py-4">
            <p className="fw-bold mb-3">
              Now, let's be real—if you're someone who values the finer things
              in life, then you know the price is insignificant compared to the
              experience you're about to step into.
            </p>
            <p className="fw-bold mb-3">
              This is an exclusive opportunity that's reserved for those who
              understand that some things in life can't be measured by price
              alone—they're measured by the moments they create.
            </p>
            <p className="fw-bold mb-3">
              But remember, this isn't a deal that lasts forever.
            </p>
            <p className="fw-bold mb-3">
              Spots are limited, and time is ticking.
            </p>
            <p className="fw-bold mb-3">
              Click below to secure your dream staycation, or risk letting this
              once-in-a-lifetime experience slip away.
            </p>
            <p className="mb-3">
              Your perfect holiday is waiting for you. The only question is…
              will you step into it?
            </p>
            <p className="fw-bold">
              Pick from our specially designed packages what suits your style
              and needs this holiday.
            </p>
          </div>
        </section>
      </div>
      <SpecialPackages />
      <div className="w-80 mx-auto">
        <WhatsappBtn />
        {/* <section>
          <div className="mx-auto" style={{ width: '50%' }}>
            <div style={{ textAlign: 'center' }} className="py-4">
              <h3 style={{ color: '#C93333' }} className=" fs-28 py-1">
                Give us your best email address below to be up to date
              </h3>
              <p className="fs-20 m-0">
                Would you like to kept informed about the Holiday Bliss
                Staycation, then fill the form below
              </p>
            </div>
            <div
              className="mx-auto"
              // className=" inset-0 w-full "
              style={{
                backgroundImage: ` url(${formBg})`,
                // height: '100vh',
                backgroundPosition: 'bottom',
                borderRadius: '15px',
              }}
            >
              <div className="p-4">
                <form>
                  <div className="mb-3">
                    <label htmlFor="accountName" className="form-label">
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountName"
                      // value={accountName}
                      placeholder="Your name"
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="accountName" className="form-label">
                      Your Email Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="accountName"
                      // value={accountName}
                      placeholder="example@example.com"
                      readOnly
                    />
                  </div>
                  <button
                    style={{ backgroundColor: '#C93333' }}
                    className=" w-full text-white py-2 rounded-10"
                  >
                    Submit
                  </button>
                  <WhatsappBtn width="w-100" />
                </form>
              </div>
            </div>
          </div>
        </section> */}
        <section>
          <h3
            style={{ color: '#C93333', textAlign: 'center' }}
            className="uppercase fs-32 py-4"
          >
            Gallery
          </h3>
          <Container>
            <Row>
              {galleryImages.map((image, idx) => (
                <Col key={idx} xs={12} md={4} className="mb-4">
                  <img
                   loading="lazy" 
                    src={image.src}
                    alt={`Gallery ${idx + 1}`}
                    className="img-fluid rounded w-100"
                  />
                </Col>
              ))}
            </Row>
          </Container>
          <div style={{ marginBottom: '6rem' }}>
            <WhatsappBtn />
          </div>
        </section>
      </div>

      <StaycationFooter />
    </div>
  )
}

export default Staycation
