import React from 'react'

const ViewApartments = () => {
  return (
    <>
      <h5>View Shortlets</h5>
      <div className="d-flex flex-column flex-md-row gap-3 mt-2">
        <div className="flex-column d-flex">
          <a
            href="https://kuula.co/share/collection/7ZCNh?logo=1&info=0&logosize=124&fs=1&vr=1&zoom=1&sd=1&initload=0&autorotate=0.22&thumbs=-1"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary fs-14 fw-bold"
          >
            Shortlet in Ikoyi<span className="fs-10"> (virtual tour)</span>{' '}
            &nbsp;
            <i className="fa-regular fa-arrow-up-right"></i>
          </a>
          <a
            href="https://youtube.com/shorts/bYXT2B2Zfig?feature=share"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary fs-14 fw-bold"
          >
            Shortlet in Ikoyi &nbsp;
            <i className="fa-regular fa-arrow-up-right"></i>
          </a>
        </div>
        <div className="flex-column d-flex">
          <a
            href="https://kuula.co/share/collection/7Zn5x?logo=1&info=0&fs=1&vr=1&sd=1&initload=0&autorotate=0.22&thumbs=-1"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary fs-14 fw-bold"
          >
            Shortlet in Lekki <span className="fs-10">(virtual tour)</span>{' '}
            &nbsp;
            <i className="fa-regular fa-arrow-up-right"></i>
          </a>
          <a
            href="https://youtube.com/shorts/qEtVRrl2Tl0?feature=share"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary fs-14 fw-bold"
          >
            Shortlet in Lekki &nbsp;
            <i className="fa-regular fa-arrow-up-right"></i>
          </a>
        </div>
        <div className="flex-column d-flex">
          {/* <a
            href="https://kuula.co/share/collection/7ZCNh?logo=1&info=0&logosize=124&fs=1&vr=1&zoom=1&sd=1&initload=0&autorotate=0.22&thumbs=-1"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary fs-14 fw-bold"
          >
            Shortlet in VI <span className="fs-10">(virtual tour)</span> &nbsp;
            <i className="fa-regular fa-arrow-up-right"></i>
          </a> */}
          <a
            href="https://youtu.be/kAiHxpq-zYg"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary fs-14 fw-bold"
          >
            Shortlet in VI &nbsp;
            <i className="fa-regular fa-arrow-up-right"></i>
          </a>
        </div>
      </div>
    </>
  )
}

export default ViewApartments
