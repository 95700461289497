import React, { useState, useEffect } from 'react'

export interface SlideImage {
  src: string
  alt: string
}

export interface AutoImageSliderProps {
  images: SlideImage[]
  interval?: number
  height?: string 
}

const AutoImageSlider: React.FC<AutoImageSliderProps> = ({
  images,
  interval = 5000,
  height = '30rem' 
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1,
      )
    }, interval)

    return () => clearInterval(timer)
  }, [images.length, interval])

  return (
    <div className="position-relative w-100 " >
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          className={`w-100 h-90 position-absolute top-0 start-0 rounded-4 img-fluid ${
            index === currentImageIndex ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            objectFit: 'cover',
            transition: 'opacity 0.5s ease',
            height: height,
          }}
        />
      ))}
    </div>
  )
}

export default AutoImageSlider
