import React, { useEffect, useState } from 'react'
import StaycationLayout from './StaycationLayout'
import { Nav, Tab, Container } from 'react-bootstrap'
import {
  BronzePackageContent,
  CustomizedPackageContent,
  GoldPackageContent,
  SilverPackageContent,
} from 'components/Staycation/package-component'
import xmasGift from '../../assets/images/xmasgift.png'
import { useLocation, useNavigate } from 'react-router-dom'

const tabs = [
  {
    eventKey: 'bronze',
    title: 'Bronze Package (3 Days)',
    content: <BronzePackageContent />,
    identifier: 'bronze',
  },
  {
    eventKey: 'silver',
    title: 'Silver Package (5 Days)',
    content: <SilverPackageContent />,
    identifier: 'silver',
  },
  {
    eventKey: 'gold',
    title: 'Gold Package (7 Days)',
    content: <GoldPackageContent />,
    identifier: 'gold',
  },
  {
    eventKey: 'customize',
    title: 'Customize your staycation (3 Days minimum)',
    content: <CustomizedPackageContent />,
    identifier: 'customize',
  },
]

const Packages = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [key, setKey] = useState<string>(() => {
    // const urlParams = new URLSearchParams(location.search)
    const packageParam = location.search.substring(1)

    const matchedTab = tabs.find((tab) => packageParam === tab.identifier)
    return matchedTab ? matchedTab.eventKey : tabs[0].eventKey
  })
  const handleTabSelect = (k: string | null) => {
    if (k) {
      const selectedTab = tabs.find((tab) => tab.eventKey === k)

      if (selectedTab) {
        navigate(`${location.pathname}?${selectedTab.identifier}`, {
          replace: true,
        })
        setKey(k)
      }
    }
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const packageId = urlParams.get('package')

    if (packageId) {
      const matchedTab = tabs.find((tab) => tab.identifier === packageId)
      if (matchedTab) {
        setKey(matchedTab.eventKey)
      }
    }
  }, [location.search])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [key, location])

  return (
    <StaycationLayout title="STAYCATION SHORTLETS PACKAGES">
      <Container className="z-50 relative">
        <Tab.Container
          id="packages-tabs"
          activeKey={key}
          onSelect={handleTabSelect}
        >
          <div
            className="overflow-auto absolute py-4"
            style={{ top: '-5.8rem', left: '0' }}
          >
            <Nav variant="tabs" className="mb-3 flex-nowrap">
              {tabs.map((tab) => (
                <Nav.Item key={tab.eventKey} className="flex-shrink-0">
                  <Nav.Link
                    eventKey={tab.eventKey}
                    className={`
                    no-underline fw-bold py-3 relative
                    ${
                      key === tab.eventKey
                        ? 'text-black bg-white'
                        : 'text-white '
                    }
                  `}
                  >
                    {key === tab.eventKey && (
                      <img
                        src={xmasGift}
                        alt="xmas cap"
                        className="absolute  transform"
                        style={{ top: '-18px', left: '-18px', width: '2.5rem' }}
                      />
                    )}
                    {tab.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <Tab.Content className="pt-12">
            {tabs.map((tab) => (
              <Tab.Pane key={tab.eventKey} eventKey={tab.eventKey}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Container>
    </StaycationLayout>
  )
}

export default Packages
