import React from 'react'

const StaycationFooter = () => {
  return (
    <div style={{ backgroundColor: '#333333' }} className="text-white py-4">
      <div className="container">
        <div className="row gy-4 align-items-center">
          <div className="col-12 col-md-4">
            <i className="fa-solid fa-asterisk fa-lg"></i>
            <h4 className="my-2 fs-32">Get in touch with us</h4>
            <p className="fs-14" style={{color:"#FCFCFC"}}>
              The <span className='fw-bold'>Holiday Bliss Staycation</span>is an annual Christmas
              event organized by Krent Space Krent is a leading real estate
              platform dedicated to making property rentals, purchases, and
              shortlets accessible and reliable for Nigerians.
            </p>
          </div>

          <div className="col-12 col-md-4">
            <div className="mt-4">
              <i className="fa-light fa-location-dot fa-lg"></i>
              <p className="mt-2">
                H3 Bayview estate <br /> Ikate Lekki Lagos
              </p>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div>
              <i className="fa-brands fa-whatsapp fa-2xl"></i>

              <p className="mt-2">
                <a href="tel:+2349118008008" className="text-white fw-bold">
                  Chat With Booking Agent
                </a>
              </p>
              <p>
                <a href="tel:+2349118008008" className="text-white">
                  Chat Directly With Someone in Charge
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaycationFooter
