import React from 'react'
import packageBg from '../../assets/images/staycation/packageBg.png'
import bronze from '../../assets/images/staycation/bronze.png'
import silver from '../../assets/images/staycation/silver.png'
import gold from '../../assets/images/staycation/gold.png'
import custom from '../../assets/images/staycation/custom.png'
import { Col, Container, Row } from 'react-bootstrap'

const packages = [
  {
    name: 'BRONZE',
    duration: '3 DAYS',
    price: '₦3.75m',
    image: bronze,
    features: [
      'Overnight stay (8-10 guests)',
      'Silent Disco Party + BBQ (30 guests)',
      'Game night (30 guests)',
      'Free Breakfast',
      'Premium Concierge Services',
      'Booking Assistance',
    ],
  },
  {
    name: 'SILVER',
    duration: '5 DAYS',
    price: '₦6.4m',
    image: silver,
    features: [
      'Overnight stay (8-10 guests)',
      'Silent Disco Party + BBQ (30 guests)',
      'Dine with a Renowned chef (14 guests)',
      'Game night (30 guests)',
      'Free Breakfast ',
      'Premium Concierge Services',
      'Booking Assistance',
    ],
  },
  {
    name: 'GOLD',
    duration: '7 DAYS',
    price: '₦8.5m',
    image: gold,
    features: [
      'Overnight stay (8-10 guests)',
      'Silent Disco Party + BBQ (30 guests)',
      'Dine with a Renowned chef (14 guests)',
      'Sip & paint with cocktails (30 guests)',
      'Game night (30 guests)',
      'Free Breakfast ',
      'Premium Concierge Services',
      'Booking Assistance',
    ],
  },
  {
    name: 'CUSTOM',
    image: custom,
    description:
      'Freedom to choose exactly what you want during your staycation. Just reach out and we will get you sorted.',
  },
]

export const SpecialPackages = () => {
  return (
    <section className="">
      <div className="relative">
        <div
          className=" inset-0 w-full "
          style={{
            backgroundImage: ` url(${packageBg})`,
          }}
        >
          <div className="absolute" />

          <div className="relative z-10 h-100 flex items-center justify-center py-5">
            <div>
              <h2 className=" mb-2 font-display text-white text-center pb-4">
                SPECIAL PACKAGES
              </h2>
              <div className="all-pkg">
                <Container>
                  <Row className="flex-lg-row flex-column gap-3 pb-4">
                    {packages.map((pkg, idx) => (
                      <Col
                        key={idx}
                        className="text-center border-lg-end-0 ind-pkg"
                      >
                        <div className="text-white h-full flex flex-col">
                          <div className='flex flex-col items-center justify-center'>
                            <img
                              src={pkg.image}
                              alt={`Package ${idx + 1}`}
                              className="img-fluid rounded mb-3"
                              style={{ width: '90px' }}
                            />
                            <div
                              className="my-1 text-center "
                              style={{ color: '#292929', height: "100px" }}
                            >
                              <h4 style={{ color: '#C93333' }}>{pkg.name}</h4>
                              {pkg.duration && (
                                <p className="my-1 fs-18">({pkg.duration})</p>
                              )}
                              {pkg.price && (
                                <h4 className="fs-30">{pkg.price}</h4>
                              )}
                            </div>
                          </div>
                          <div
                            className="flex-grow fs-16 fw-600 mt-2"
                            style={{ color: '#292929' }}
                          >
                            {pkg.name !== 'CUSTOM' ? (
                              pkg.features &&
                              pkg.features.map((feature, featureIdx) => (
                                <div
                                  key={featureIdx}
                                  className="d-flex align-items-center gap-2 my-3"
                                >
                                  <i
                                    className="fa-solid fa-square-check fa-xl"
                                    style={{ color: '#4F820C' }}
                                  ></i>

                                  <p
                                    className="m-0 "
                                    style={{ textAlign: 'left' }}
                                  >
                                    {feature}
                                  </p>
                                </div>
                              ))
                            ) : (
                              <p className="text-justify mt-3">{pkg.description}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
                <hr style={{ border: '1px dashed #999999' }} className="my-2" />
                <div className="py-3 px-md-0 px-4">
                  <h5 style={{ color: '#292929' }}>
                    Extras (Available at Additional Costs)
                  </h5>
                  {['Police Escort', 'Alcohol Sourcing Assistance'].map(
                    (extra, idx) => (
                      <div
                        key={idx}
                        className="d-flex align-items-center gap-2 my-3"
                      >
                        <i
                          className="fa-solid fa-square-check fa-xl"
                          style={{ color: '#4F820C' }}
                        ></i>

                        <p
                          className="m-0 fw-bold"
                          style={{ textAlign: 'left', color: '#292929' }}
                        >
                          {extra}
                        </p>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}