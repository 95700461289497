import { useNavigate } from 'react-router-dom'

type prop = {
  type: string
  setPage: (num: number) => void
}

const RadioSearchNav = ({ type, setPage }: prop) => {
  const navigate = useNavigate()

  return (
    <div
      className="btn-group"
      role="group"
      aria-label="Basic radio toggle button group"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const int = e.target.value
        setPage(1)
        navigate(`/property-for-${int}`, { replace: false })
      }}
    >
      <input
        type="radio"
        className="btn-check "
        defaultValue="rent"
        name="btnradio"
        id="btnradio1"
        autoComplete="off"
        checked={type === 'rent'}
        readOnly
      />
      <label
        className="btn btn-tab-button px-3 px-md-5 py-3 rounded-bottom-left-0 "
        htmlFor="btnradio1"
      >
        For Rent
      </label>

      <input
        type="radio"
        className="btn-check"
        defaultValue="sale"
        name="btnradio"
        id="btnradio2"
        autoComplete="off"
        checked={type === 'sale'}
        readOnly
      />
      <label
        className="btn btn-tab-button px-3 px-md-5 py-3"
        htmlFor="btnradio2"
      >
        For Sale
      </label>

      <input
        type="radio"
        className="btn-check"
        defaultValue="shortlet"
        name="btnradio"
        id="btnradio3"
        autoComplete="off"
        checked={type === 'shortlet'}
        readOnly
      />
      <label
        className="btn btn-tab-button px-3 px-md-5 py-3 rounded-bottom-right-0"
        htmlFor="btnradio3"
      >
        For ShortLet
      </label>
    </div>
  )
}
export default RadioSearchNav
