import React from 'react'
import StaycationLayout from './StaycationLayout'
import Terms from 'components/Staycation/terms'

const TAndC = () => {
  return (
    <StaycationLayout title="TERMS AND CONDITIONS">
      <div className="py-4">
      <Terms/>
      </div>
    </StaycationLayout>
  )
}

export default TAndC
