import { BaseFooter } from '@shared/navbars'
import StaycationFooter from 'components/Staycation/staycation-footer'
import React, { useState } from 'react'
import bgImg from '../../assets/images/p-hero.png'
import StaycationHeader from 'components/Staycation/staycation-header'
import { ToastContainer } from 'react-toastify'

const StaycationLayout = ({ children, title }: any) => {
  const [page, setPage] = useState()
  console.log(page)

  return (
    <div className=''>
      <ToastContainer limit={1} />

      <section className="">
        <div className="relative">
          <div
            className=" inset-0 w-full bg-cover bg-bottom sc-hero-sec"
            style={{
              backgroundImage: ` url(${bgImg})`,
             
            }}
          >
            <div className="absolute inset-0 bg-black/30" />

            <StaycationHeader />

            <div className="relative z-10">
              <div className="container hero-title pt-8 ">
                <h1 className="text-white mb-2 fw-600 font-display ">
                  <span className="">{title}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {children}
      <StaycationFooter />
      <BaseFooter setPage={setPage} />
    </div>
  )
}

export default StaycationLayout
