import React from 'react'

interface WhatsappBtnProps {
  width?: string
}

const WhatsappBtn: React.FC<WhatsappBtnProps> = ({ width = 'w-100' }) => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/2349118008008', '_blank')
  }
  return (
    <div className="my-4">
      <button
        className={`whatsappBtn text-white flex gap-4 px-4 ${width}`}
        onClick={handleWhatsAppClick}
      >
        <div>
          <i className="fa-brands fa-whatsapp fa-xl"></i>
        </div>
        <div style={{ textAlign: 'left' }}>
          <h6 className="m-0 fs-14">Chat With Booking Agent</h6>
          <p className="m-0">Chat Directly With Someone in Charge</p>
        </div>
      </button>
    </div>
  )
}

export default WhatsappBtn
