import { BookStaycation } from './book-btn'
import bronzeImg from '../../assets/images/bronze.png'
import silverImg from '../../assets/images/silverImg.png'
import goldImg from '../../assets/images/gold-main.png'

import bronzeMultipleImg from '../../assets/images/bronze-multiple.png'
import bronzeMulti from '../../assets/images/multi-bronze.png'
import goldMulti from '../../assets/images/gold-multi.png'
import silverMulti from '../../assets/images/silverMulti.png'
import silverSlide1 from '../../assets/images/silverslide1.png'
import silverSlide2 from '../../assets/images/silverslide2.png'
import silverSlide3 from '../../assets/images/silverimg3.png'
import goldmini1 from '../../assets/images/gold-mini1.png'
import goldmini2 from '../../assets/images/gold-mini2.png'

import { Row, Col, Modal } from 'react-bootstrap'
import { RefObject, useRef, useState } from 'react'
import { addStaycation } from '@services/krent.service'
import { toast } from 'react-toastify'
import AutoImageSlider from './image-slider'
import Terms from './terms'
import ViewApartments from './view-apts'

const silverSliderImages = [
  {
    src: silverSlide1,
    alt: 'silver img',
  },
  {
    src: silverSlide2,
    alt: 'silver img2',
  },
  {
    src: silverSlide3,
    alt: 'silver img2',
  },
]
export const BronzePackageContent = () => {
  return (
    <div className="py-4 text-justify">
      <Row className="py-4 my-4 align-items-center">
        <Col md={6}>
          <img
            src={bronzeImg}
            alt="bronze img"
            className="img-fluid rounded-4"
          />
        </Col>
        <Col md={6}>
          <h5 className="mt-md-0 mt-3 fs-24">Overview</h5>

          <h6 className="my-4 fs-20">
            <i className="fa-light fa-location-dot fa-lg"></i>
            &nbsp; Location: Lekki, VI, Ikoyi
          </h6>
          <p className="fs-16 ">
            Looking for a simple yet amazing getaway? The Bronze Package gives
            you access to a 4-bedroom shortlet with all the basics you need to
            relax and have fun. Enjoy a silent disco party, BBQ, and a games
            night with your favorite people. It's the perfect package for
            creating chill vibes without breaking the bank!
          </p>
          <h4 className="fw-bold my-3">
            {/* <span className="text-decoration-line-through">$3,000</span>&nbsp; */}
            $2,500 (10% OFF!)
          </h4>
          <BookStaycation btnText="Book package now" hasPackage="bronze" />
        </Col>
      </Row>
      <Row className="py-4 my-4 align-items-center">
        <Col md={6}>
          <h5 className="fs-18">Highlights</h5>

          <p className="mt-3 fs-16">
            <span className="fw-bold">
              3-4 Bedroom Shortlet (8-10 Guests):{' '}
            </span>
            Our shortlets are located in some of the best neighborhoods in
            Lagos: Lekki, Ikoyi, and VI. These homes are designed for comfort
            and style, offering spacious living rooms, Netflix-ready TVs,
            fully-equipped kitchens, and cozy bedrooms to ensure you feel at
            home.
            <br /> Safety is our top priority, with round-the-clock security, so
            you can relax knowing you're in good hands. Plus, they're located in
            quiet, serene environments—perfect for unwinding.
          </p>
          <p className="fs-16">The amenities take it up a notch:</p>
          <ul className="sc-list-unstyled fs-16">
            {[
              'A pool to relax and cool off whenever you want',
              'A cabana where you can chill with friends or get quiet time',
              'A table tennis setup',
              'A PS5 for gaming',
              'A grand piano for music lovers',
              'A designated smoking area for convenience',
              "24/7 electricity so there's never a dull moment",
              'High-speed internet to keep you connected',
              'Ample parking space for you and your guests',
            ].map((amenity, index) => (
              <li key={index} className="mb-2">
                {amenity}
              </li>
            ))}
          </ul>
          <ViewApartments />
        </Col>
        <Col md={6}>
          <img
            src={bronzeMultipleImg}
            alt="bronze img"
            className="img-fluid rounded-4"
          />
        </Col>
      </Row>

      <Row className="py-4 my-4 align-items-center">
        <Col md={6}>
          <img
            src={bronzeMulti}
            alt="bronze img"
            className="img-fluid rounded-4"
          />
        </Col>
        <Col md={6}>
          <div className="mt-md-0 mt-4">
            <p className="fs-16">
              <span className="fw-bold">
                Silent Disco Party + BBQ (30 Guests)
              </span>
              : Ever tried a silent disco? It's a party like no other. Everyone
              gets headphones, picks their favorite music, and the vibe is
              unmatched. Pair this with a sizzling BBQ where you can enjoy
              perfectly grilled meats and sides. This setup can host up to 30
              guests, making it ideal for bonding with friends, catching up with
              family, and creating memories you'll all talk about for years.
              It's fun, laid-back, and perfect for an unforgettable night!
            </p>
            <p className="my-4 fs-16">
              <span className="fw-bold">Games Night (30 Guests)</span>: What's
              better than laughing and reconnecting with friends over games?
              With games like Jenga, classic card games, PS gaming sessions, and
              a lineup of more exciting options, there's something for everyone
              to enjoy. This setup also works for up to 30 guests, so there's
              enough room for everyone to join the fun. Want to catch up with
              old friends or create new memories with family? This is your
              chance to unwind, have fun, and show off your gaming skills.
            </p>
            <BookStaycation btnText="Book package" hasPackage="bronze" />
          </div>
        </Col>
      </Row>
      <BonusList gold />
    </div>
  )
}
export const SilverPackageContent = () => {
  return (
    <div className="py-4 text-justify">
      <Row className="py-4 my-4 align-items-center">
        <Col md={6}>
          <img
            src={silverImg}
            alt="bronze img"
            className="img-fluid rounded-4"
          />
        </Col>
        <Col md={6}>
          <h5 className="mt-md-0 mt-3 fs-24">Overview</h5>

          <h6 className="my-4 fs-20">
            <i className="fa-light fa-location-dot fa-lg"></i>
            &nbsp; Location: Lekki, VI, Ikoyi
          </h6>
          <p className="fs-16">
            Want a little extra? The Silver Package brings all the fun of the
            Bronze, plus a fine dining experience with a chef. Enjoy great food,
            party vibes with a silent disco, and an epic games night with
            friends and family. It's all about balancing comfort and
            unforgettable moments.
          </p>
          <h4 className="fw-bold my-3">
            {/* <span className="text-decoration-line-through">$5,500</span>&nbsp; */}
            $4,250 (10% OFF!)
          </h4>
          <BookStaycation btnText="Book package now" hasPackage="silver" />
        </Col>
      </Row>

      <Row className="py-4 my-4 align-items-center">
        <Col md={6}>
          <h5 className="fs-18">Highlights</h5>
          <p className="mt-3 fs-16 ">
            <span className="fw-bold fs-16">
              3-4 Bedroom Shortlet (8-10 Guests):
            </span>
            Our shortlets are located in some of the best neighborhoods in
            Lagos: Lekki, Ikoyi, and VI. These homes are designed for comfort
            and style, offering spacious living rooms, Netflix-ready TVs,
            fully-equipped kitchens, and cozy bedrooms to ensure you feel at
            home.
            <br /> Safety is our top priority, with round-the-clock security, so
            you can relax knowing you're in good hands. Plus, they're located in
            quiet, serene environments—perfect for unwinding.
          </p>
          <p className="fs-16">The amenities take it up a notch:</p>
          <ul className="sc-list-unstyled fs-16">
            {[
              'A pool to relax and cool off whenever you want',
              'A cabana where you can chill with friends or get quiet time',
              'A table tennis setup',
              'A PS5 for gaming',
              'A grand piano for music lovers',
              'A designated smoking area for convenience',
              "24/7 electricity so there's never a dull moment",
              'High-speed internet to keep you connected',
              'Ample parking space for you and your guests',
            ].map((amenity, index) => (
              <li key={index} className="mb-2">
                {amenity}
              </li>
            ))}
          </ul>
          <ViewApartments />
        </Col>
        <Col md={6}>
          <img
            src={silverMulti}
            alt="bronze img"
            className="img-fluid rounded-4"
          />
        </Col>
      </Row>

      <Row className="py-4 my-4">
        <Col xs={12} md={6} className="mb-4 mb-md-0">
          <AutoImageSlider images={silverSliderImages} interval={3000} />
        </Col>
        <Col xs={12} md={6}>
          <div className="slider-text">
            <p className="fs-16 mb-3 lh-xl">
              <span className="fw-bold">
                Dine with a Renowned Chef (14 Guests)
              </span>
              : Imagine sitting down with your family and friends to enjoy
              mouthwatering meals prepared by renowned chefs like Chef Tee, Chef
              Kewa, Chef Tucker, Chef Zoey… This isn't your usual dinner—this is
              a premium fine dining experiencenwhere you can wining with up to
              10 friends or family members and enjoy flavors of both Nigerian
              and international cuisines.
            </p>
            <p className="fs-16 lh-xl my-3">
              <span className="fw-bold">
                Silent Disco Party + BBQ (30 Guests)
              </span>
              : Ever tried a silent disco? It's a party like no other. Everyone
              gets headphones, picks their favorite music, and the vibe is
              unmatched. Pair this with a sizzling BBQ where you can enjoy
              perfectly grilled meats and sides. This setup can host up to 30
              guests, making it ideal for bonding with friends, catching up with
              family, and creating memories you'll all talk about for years.
              It's fun, laid-back, and perfect for an unforgettable night!
            </p>
            <p className="my-3 fs-16 lh-xl">
              <span className="fw-bold">Games Night (30 Guests)</span>: What's
              better than laughing and reconnecting with friends over games?
              With games like Jenga, classic card games, PS gaming sessions, and
              a lineup of more exciting options, there's something for everyone
              to enjoy. This setup also works for up to 30 guests, so there's
              enough room for everyone to join the fun. Want to catch up with
              old friends or create new memories with family? This is your
              chance to unwind, have fun, and show off your gaming skills. With
              space for up to 30 guests, there's plenty of room for everyone.
            </p>
            <BookStaycation btnText="Book package" hasPackage="silver" />
          </div>
        </Col>
      </Row>
      <div className="py-4 ">
        <BonusList />
      </div>
    </div>
  )
}
export const GoldPackageContent = () => {
  return (
    <div className="py-4 text-justify">
      <Row className="py-4 my-4 align-items-center">
        <Col md={6}>
          <img src={goldImg} alt="bronze img" className="img-fluid rounded-4" />
        </Col>
        <Col md={6}>
          <h5 className="mt-md-0 mt-3 fs-24">Overview</h5>

          <h6 className="my-4 fs-20">
            <i className="fa-light fa-location-dot fa-lg"></i>
            &nbsp; Location: Lekki, VI, Ikoyi
          </h6>
          <p className="fs-16">
            Ready to go all out? The Gold Package is the ultimate staycation
            experience! Enjoy a luxury shortlet, a chef-prepared dinner, a
            silent disco party, BBQ, and a sip-and-paint session with cocktails.
            End it all with a fun-packed games night. This is the kind of
            getaway you won't stop talking about!
          </p>
          <h4 className="fw-bold my-3">
            {/* <span className="text-decoration-line-through">$6,000</span>&nbsp; */}
            $5,700 (13% OFF!)
          </h4>
          <BookStaycation btnText="Book package now" hasPackage="gold" />
        </Col>
      </Row>

      <Row className="py-4 my-4 align-items-center">
        <Col md={6}>
          <h5 className="fs-18">Highlights</h5>
          <p className="mt-3 fs-16">
            <span className="fw-bold">
              {' '}
              3-4 Bedroom Shortlet (8-10 Guests):{' '}
            </span>
            Our shortlets are located in some of the best neighborhoods in
            Lagos: Lekki, Ikoyi, and VI. These homes are designed for comfort
            and style, offering spacious living rooms, Netflix-ready TVs,
            fully-equipped kitchens, and cozy bedrooms to ensure you feel at
            home.
            <br /> Safety is our top priority, with round-the-clock security, so
            you can relax knowing you're in good hands. Plus, they're located in
            quiet, serene environments—perfect for unwinding.
          </p>
          <p className="fs-16">The amenities take it up a notch:</p>
          <ul className="sc-list-unstyled fs-16">
            {[
              'A pool to relax and cool off whenever you want',
              'A cabana where you can chill with friends or get quiet time',
              'A table tennis setup',
              'A PS5 for gaming',
              'A grand piano for music lovers',
              'A designated smoking area for convenience',
              "24/7 electricity so there's never a dull moment",
              'High-speed internet to keep you connected',
              'Ample parking space for you and your guests',
            ].map((amenity, index) => (
              <li key={index} className="mb-2">
                {amenity}
              </li>
            ))}
          </ul>
          <ViewApartments />
        </Col>
        <Col md={6}>
          <img
            src={goldMulti}
            alt="bronze img"
            className="img-fluid rounded-4"
          />
        </Col>
      </Row>

      <Row className="py-4 my-4">
        <Col md={6}>
          <div className="mb-3">
            <AutoImageSlider
              images={silverSliderImages}
              interval={3000}
              height={'20rem'}
            />
          </div>

          <div className="d-flex " style={{ marginTop: '14rem' }}>
            <div className="w-50">
              <img
                src={goldmini1}
                alt="bronze img"
                className="img-fluid rounded-4"
              />
            </div>
            <div className="w-50">
              <img
                src={goldmini2}
                alt="bronze img"
                className="img-fluid rounded-4"
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="mt-md-0 mt-3">
            <p className="fs-16 mb-4 lh-xl">
              <span className="fw-bold">
                Dine with a Renowned Chef (14 Guests)
              </span>
              : Imagine sitting down with your family and friends to enjoy
              mouthwatering meals prepared by renowned chefs like Chef Tee, Chef
              Kewa, Chef Tucker, Chef Zoey… This isn't your usual dinner—this is
              a premium fine dining experiencenwhere you can wining with up to
              10 friends or family members and enjoy flavors of both Nigerian
              and international cuisines.
            </p>
            <p className="fs-16 my-4 lh-xl">
              <span className="fw-bold">
                Silent Disco Party + BBQ (30 Guests)
              </span>
              : Ever tried a silent disco? It's a party like no other. Everyone
              gets headphones, picks their favorite music, and the vibe is
              unmatched. Pair this with a sizzling BBQ where you can enjoy
              perfectly grilled meats and sides. This setup can host up to 30
              guests, making it ideal for bonding with friends, catching up with
              family, and creating memories you'll all talk about for years.
              It's fun, laid-back, and perfect for an unforgettable night!
            </p>
            <p className="my-4 fs-16 lh-xl">
              <span className="fw-bold">Games Night (30 Guests)</span>: What's
              better than laughing and reconnecting with friends over games?
              With games like Jenga, classic card games, PS gaming sessions, and
              a lineup of more exciting options, there's something for everyone
              to enjoy.This setup also works for up to 30 guests, so there's
              enough room for everyone to join the fun.
              <br /> Want to catch up with old friends or create new memories
              with family? This is your chance to unwind, have fun, and show off
              your gaming skills. With space for up to 30 guests, there's plenty
              of room for everyone.
            </p>
            <p className="my-4 fs-16 lh-xl">
              <span className="fw-bold">
                Sip & Paint with Cocktails (30 Guests)
              </span>
              : Grab a canvas and a paintbrush—this is all about relaxing and
              having fun with your people. You also get to enjoy delicious
              cocktails by professional mixologists. You don't need to be an
              artist to enjoy it; it's just good vibes, great drinks, and
              quality time. It's perfect for a group of up to 30 friends and
              family.
            </p>
            <BookStaycation btnText="Book package" hasPackage="gold" />
          </div>
        </Col>
      </Row>
      <BonusList />
    </div>
  )
}

export const CustomizedPackageContent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState<string | null>(null)
  const [total, setTotal] = useState(0)
  const [numberOfDays, setNumberOfDays] = useState(0)
  const [accommodationCost, setAccommodationCost] = useState(0)
  const [termsModal, showTermsModal] = useState(false)
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    location: '',
    arrivalDate: '',
    departureDate: '',
    termsAccepted: false,
    highlight_silent_disco: false,
    highlight_dine_with_chef: false,
    highlight_sip_and_paint: false,
    highlight_games_night: false,
  })
  const formSectionRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const scrollToForm = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }
  const NIGHTLY_RATE = 490
  const highlightPrices = {
    highlight_silent_disco: 800,
    highlight_dine_with_chef: 1000,
    highlight_sip_and_paint: 900,
    highlight_games_night: 500,
  }

  const isFormValid = () => {
    return (
      formData.fullName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.phone.trim() !== '' &&
      formData.location !== '' &&
      formData.arrivalDate !== '' &&
      formData.departureDate !== ''
    )
  }

  const isDateFullyBooked = (date: string) => {
    const checkDate = new Date(date)
    const startBlockedDate = new Date('2024-12-01')
    const endBlockedDate = new Date('2024-12-09')

    return checkDate >= startBlockedDate && checkDate <= endBlockedDate
  }

  // const getMinDepartureDate = (arrivalDate: string) => {
  //   if (!arrivalDate) return ''
  //   const date = new Date(arrivalDate)
  //   date.setDate(date.getDate() + 3)
  //   return date.toISOString().split('T')[0]
  // }

  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0]
  }

  const getValidDateRange = () => {
    const startDate = new Date('2024-12-01')
    const endDate = new Date('2025-01-05')
    return {
      min: formatDate(startDate),
      max: formatDate(endDate),
    }
  }

  const calculateNights = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return 0
    const start = new Date(startDate)
    const end = new Date(endDate)
    const diffTime = Math.abs(end.getTime() - start.getTime())
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }

  const updateTotalCost = (
    updatedFormData: typeof formData,
    nights: number,
  ) => {
    const highlightsCost = (Object.keys(highlightPrices) as Array<
      keyof typeof highlightPrices
    >)
      .filter((key) => updatedFormData[key])
      .reduce((sum, key) => sum + highlightPrices[key], 0)

    const nightlyCost = nights * NIGHTLY_RATE
    setAccommodationCost(nightlyCost)
    setTotal(highlightsCost + nightlyCost)
  }
  const getMinDepartureDate = (arrivalDate: string) => {
    if (!arrivalDate) return ''
    const date = new Date(arrivalDate)
    date.setDate(date.getDate() + 3)
    return formatDate(date)
  }

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: 'arrivalDate' | 'departureDate',
  ) => {
    const selectedDate = e.target.value

    if (isDateFullyBooked(selectedDate)) {
      toast.error(
        'December 1-9 are fully booked. Please select different dates.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        },
      )
      return
    }

    setFormData((prev) => {
      let updatedFormData = { ...prev }

      if (fieldName === 'arrivalDate') {
        const currentDeparture = new Date(prev.departureDate)
        const newArrival = new Date(selectedDate)
        const minDeparture = new Date(getMinDepartureDate(selectedDate))

        if (currentDeparture <= newArrival || currentDeparture < minDeparture) {
          updatedFormData.departureDate = getMinDepartureDate(selectedDate)
        }
        updatedFormData.arrivalDate = selectedDate
      } else {
        const newDeparture = new Date(selectedDate)
        const arrival = new Date(prev.arrivalDate)
        const minDeparture = new Date(getMinDepartureDate(prev.arrivalDate))

        if (newDeparture < arrival || newDeparture < minDeparture) {
          toast.error(
            'Checkout date must be at least 3 nights after check-in date',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            },
          )
          return prev
        }
        updatedFormData.departureDate = selectedDate
      }

      const nights = calculateNights(
        updatedFormData.arrivalDate,
        updatedFormData.departureDate,
      )
      setNumberOfDays(nights)
      updateTotalCost(updatedFormData, nights)

      return updatedFormData
    })
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target

    if (type === 'date') {
      handleDateChange(
        e as React.ChangeEvent<HTMLInputElement>,
        name as 'arrivalDate' | 'departureDate',
      )
      return
    }

    const isChecked =
      type === 'checkbox' ? (e.target as HTMLInputElement).checked : false

    setFormData((prev) => {
      if (name === 'termsAccepted') {
        return {
          ...prev,
          termsAccepted: isChecked,
        }
      }

      let updatedFormData = {
        ...prev,
        [name]: type === 'checkbox' ? isChecked : value,
      } as typeof prev

      if (type === 'checkbox' && name.startsWith('highlight_')) {
        if (numberOfDays === 3) {
          const currentSelectedCount = Object.keys(highlightPrices).filter(
            (key) =>
              key !== name &&
              updatedFormData[key as keyof typeof updatedFormData],
          ).length

          if (isChecked && currentSelectedCount >= 3) {
            toast.error(
              'For 3-day stays, you can only select up to 3 highlight activities',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              },
            )
            return prev
          }
        }
      }

      updateTotalCost(updatedFormData, numberOfDays)
      return updatedFormData
    })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!formData.termsAccepted) {
      setSubmitError('Please accept the terms and conditions')
      return
    }

    setIsSubmitting(true)
    setSubmitError(null)

    try {
      const selectedHighlights = []
      if (formData.highlight_silent_disco) {
        selectedHighlights.push('Silent Disco Party + BBQ')
      }
      if (formData.highlight_dine_with_chef) {
        selectedHighlights.push('Dine with a Renowned Chef ')
      }
      if (formData.highlight_sip_and_paint) {
        selectedHighlights.push('Sip & Paint with Cocktails')
      }
      if (formData.highlight_games_night) {
        selectedHighlights.push('Games Night')
      }

      const payload = {
        fullName: formData.fullName,
        email: formData.email,
        phone: formData.phone,
        location: formData.location,
        package: 'customized',
        arrivalDate: formData.arrivalDate,
        departureDate: formData.departureDate,
        highlights: selectedHighlights,
      }

      await addStaycation(payload)
      const currentUrl = window.location.href
      const urlWithParam =
        currentUrl + (currentUrl.includes('?') ? '&booked' : '?booked')

      window.history.replaceState(null, '', urlWithParam)

      toast.success('Staycation booked successfully!')
      showTermsModal(false)

      setFormData({
        fullName: '',
        email: '',
        phone: '',
        location: '',
        arrivalDate: '',
        departureDate: '',
        termsAccepted: false,
        highlight_silent_disco: false,
        highlight_dine_with_chef: false,
        highlight_sip_and_paint: false,
        highlight_games_night: false,
      })
      setTotal(0)
      setNumberOfDays(0)
    } catch (error) {
      setSubmitError('Failed to book staycation. Please try again.')
      toast.success('Failed to book, try again!')
    } finally {
      setIsSubmitting(false)
    }
  }
  const locationOptions = ['Lekki', 'VI', 'Ikoyi']
  return (
    <div className="py-4 text-justify">
      <Row className="py-4 my-4 align-items-center ">
        <Col md={6}>
          <img
            src={bronzeImg}
            alt="bronze img"
            className="img-fluid rounded-4"
          />
        </Col>
        <Col md={6}>
          <h5 className="mt-md-0 mt-3 fs-24">Overview</h5>

          <h6 className="my-4 fs-20">
            <i className="fa-light fa-location-dot fa-lg"></i>
            &nbsp; Location: Lekki, VI, Ikoyi
          </h6>
          <p className="fs-16">
            With the "Customize Your Staycation" package, you have the freedom
            to build a staycation that matches your desires. Select one or more
            highlights from our curated options and enjoy a personalized
            December getaway. This package offers you a minimum of 3 days and a
            maximum of 7 days for your staycation.
          </p>

          <div className="my-4">
            <button
              onClick={scrollToForm}
              className="bg-primary px-4 py-2 rounded-10 fw-bold text-white fs-14 d-flex items-center gap-2"
            >
              Book customized package
              <i className="fa-regular fa-arrow-up-right"></i>
            </button>
          </div>
        </Col>
      </Row>

      <div ref={formSectionRef}>
        <h6>Book customized staycation</h6>
        <div className="py-4">
          <div className="d-flex flex-column flex-md-row mb-3 justify-between gap-md-5 gap-3">
            <div className="w-full">
              <label htmlFor="fullName" className="form-label fw-bold">
                Full name
              </label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className=" w-full">
              <label htmlFor="email" className="form-label fw-bold">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row mb-3 justify-between gap-md-5 gap-3">
            <div className="w-full">
              <label htmlFor="phone" className="form-label fw-bold">
                Phone number
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="w-full">
              <label htmlFor="location" className="form-label fw-bold">
                Location
              </label>
              <select
                id="location"
                name="location"
                className="form-control"
                value={formData.location}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a location</option>
                {locationOptions.map((loc) => (
                  <option key={loc} value={loc}>
                    {loc}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row mb-3 justify-between gap-md-5 gap-3">
            <div className=" w-full">
              <label htmlFor="arrivalDate" className="form-label fw-bold">
                Check-in Date
              </label>
              <input
                type="date"
                className="form-control"
                id="arrivalDate"
                name="arrivalDate"
                value={formData.arrivalDate}
                onChange={handleInputChange}
                min={getValidDateRange().min}
                max={getValidDateRange().max}
                required
              />
            </div>
            <div className=" w-full">
              <label htmlFor="departureDate" className="form-label fw-bold">
                Check-out Date (minimum 3 nights)
              </label>
              <input
                type="date"
                className="form-control"
                id="departureDate"
                name="departureDate"
                value={formData.departureDate}
                onChange={handleInputChange}
                min={
                  formData.arrivalDate
                    ? getMinDepartureDate(formData.arrivalDate)
                    : getValidDateRange().min
                }
                max={getValidDateRange().max}
                required
              />
            </div>
          </div>
          <div className="py-4">
            <h5 className="fs-18">Available Highlights</h5>
            <p className="mt-3 fs-16">
              <span className="fw-bold">
                3-4 Bedroom Shortlet (8-10 Guests):
              </span>
              Our shortlets are located in some of the best neighborhoods in
              Lagos: Lekki, Ikoyi, and VI. These homes are designed for comfort
              and style, offering spacious living rooms, Netflix-ready TVs,
              fully-equipped kitchens, and cozy bedrooms to ensure you feel at
              home.
              <br /> Safety is our top priority, with round-the-clock security,
              so you can relax knowing you're in good hands. Plus, they're
              located in quiet, serene environments—perfect for unwinding.
            </p>
            <p className="fs-16">The amenities take it up a notch:</p>
            <ul className="sc-list-unstyled fs-16">
              {[
                'A pool to relax and cool off whenever you want',
                'A cabana where you can chill with friends or get quiet time',
                'A table tennis setup',
                'A PS5 for gaming',
                'A grand piano for music lovers',
                'A designated smoking area for convenience',
                "24/7 electricity so there's never a dull moment",
                'High-speed internet to keep you connected',
                'Ample parking space for you and your guests',
              ].map((amenity, index) => (
                <li key={index} className="mb-2">
                  {amenity}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h6 className="pb-2">
              *NOTE: THESE JUST FOR THE ACTIVITIES. THE SHORTLET PRICE WOULD BE
              ADDED TO YOUR PICK
            </h6>
            <div className="border p-3">
              <div className="d-flex gap-4 items-start mb-3">
                <input
                  type="checkbox"
                  name="highlight_silent_disco"
                  checked={formData['highlight_silent_disco']}
                  onChange={handleInputChange}
                  style={{ height: '20px', width: '20px' }}
                  className="mt-1"
                />
                <div>
                  <h5>
                    Silent Disco Party + BBQ{' '}
                    <span className="fs-14">(30 guests)</span>
                  </h5>
                  <p>
                    Dance under the stars with a private Silent Disco setup and
                    indulge in a delicious BBQ feast.
                  </p>
                  <p className="fw-bold hidden">$1000</p>
                </div>
              </div>

              <div className="d-flex gap-4 items-start mb-3">
                <input
                  type="checkbox"
                  name="highlight_dine_with_chef"
                  checked={formData['highlight_dine_with_chef']}
                  onChange={handleInputChange}
                  style={{ height: '20px', width: '20px' }}
                  className="mt-1"
                />
                <div>
                  <h5>
                    Dine with a Renowned Chef
                    <span className="fs-14">(14 guests)</span>
                  </h5>
                  <p>
                    Enjoy mouthwatering meals with Renowned Chefs like Chef Tee,
                    Chef Kewa,Chef Tucker and Chef Zoey. Friends&Family-14
                    Guests
                  </p>
                  <p className="fw-bold hidden">$1000</p>
                </div>
              </div>

              <div className="d-flex gap-4 items-start mb-3">
                <input
                  type="checkbox"
                  name="highlight_sip_and_paint"
                  checked={formData['highlight_sip_and_paint']}
                  onChange={handleInputChange}
                  style={{ height: '20px', width: '20px' }}
                  className="mt-1"
                />
                <div>
                  <h5>
                    Sip & Paint with Cocktails
                    <span className="fs-14">(30 guests)</span>
                  </h5>
                  <p>
                    Unwind with a guided painting session, complete with
                    expertly crafted cocktails for a relaxing and creative day.
                  </p>
                  <p className="fw-bold hidden">$1000</p>
                </div>
              </div>

              <div className="d-flex gap-4 items-start mb-3">
                <input
                  type="checkbox"
                  name="highlight_games_night"
                  checked={formData['highlight_games_night']}
                  onChange={handleInputChange}
                  style={{ height: '20px', width: '20px' }}
                  className="mt-1"
                />
                <div>
                  <h5>
                    Games Night
                    <span className="fs-14">(30 guests)</span>
                  </h5>
                  <p>
                    Engage in competitive fun with an extensive range of free
                    games designed to bring everyone together.
                  </p>
                </div>
              </div>

              <div className=" justify-between fw-bold fs-20 mb-2 hidden">
                <p>
                  Accommodation Cost ({numberOfDays} nights @ ${NIGHTLY_RATE}
                  /night)
                </p>
                <p>${accommodationCost.toLocaleString()}</p>
              </div>

              <div className="d-flex justify-between fw-bold fs-20">
                <p>TOTAL (Including Accommodation)</p>
                <p>${total.toLocaleString()}</p>
              </div>
            </div>
          </div>
          <BonusList />
          <div className="my-3 d-flex align-items-center">
            <input
              type="checkbox"
              id="termsAccepted"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={(e) => {
                handleInputChange(e)
                setSubmitError(null)
              }}
              className="me-2"
              required
            />
            <label htmlFor="termsAccepted" className="fs-16">
              By clicking, it means that you have agreed to{' '}
              <a href="/staycation-terms" className="text-primary underline">
                Krent's Terms and Conditions
              </a>
            </label>
          </div>

          <button
            onClick={() => {
              const currentUrl = window.location.href
              const urlWithoutBooked = currentUrl.replace(
                /[&?]booked(=[^&]*)?/g,
                '',
              )

              if (currentUrl !== urlWithoutBooked) {
                window.history.replaceState(null, '', urlWithoutBooked)
              }
              showTermsModal(true)
            }}
            className="bg-primary w-full rounded-10 py-3 text-white fw-bold mt-3"
            disabled={!formData.termsAccepted || !isFormValid()}
          >
            Submit customized staycation
          </button>
          <p className="text-red">{submitError}</p>
        </div>
      </div>
      <Modal
        show={termsModal}
        onClose={() => showTermsModal(false)}
        dismissible={false}
        size="lg"
      >
        <div className="p-4">
          <div className="d-flex justify-between">
            <p></p>
            <button
              onClick={() => showTermsModal(false)}
              className="bg-white mr-auto"
            >
              <i className="fa-regular fa-x"></i>
            </button>
          </div>
          <Terms />
          <button
            onClick={handleSubmit}
            type="submit"
            className="bg-primary w-full rounded-10 py-2 text-white fw-bold mt-3"
            disabled={!formData.termsAccepted || isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </Modal>
    </div>
  )
}

interface BonusListProps {
  gold?: boolean
}

export const BonusList: React.FC<BonusListProps> = ({ gold }) => {
  return (
    <div>
      <div>
        <h6 className="my-3 fs-20">Bonuses Included</h6>
        <p className="fs-16">
          <span className="fw-bold">Free Breakfast</span>: Wake up to freshly
          prepared meals every morning, ensuring you start your day right.
        </p>
        <p className="fs-16">
          <span className="fw-bold">Premium Concierge Services</span>: Travel
          seamlessly with complimentary pickup and drop-off services from any
          location in Lagos. Your convenience is our priority.
        </p>
        <p className="fs-16">
          <span className="fw-bold">Booking Assistance</span>: We handle your
          bookings for Lagos' best concerts, beach parties, and club outings, so
          you can enjoy a hassle-free holiday.
        </p>
      </div>
      <div className="my-4">
        <h6 className="my-3 fs-20">Extras (Available at Additional Costs)</h6>
        <p className="fs-16">
          <span className="fw-bold">Police Escort</span>: Optional escort
          service for safe and comfortable travel around Lagos.
        </p>
        <p className="fs-16">
          <span className="fw-bold">Alcohol Sourcing Assistance</span>: We
          provide access to wholesale prices for your favorite drinks, delivered
          directly to your doorstep.
        </p>
      </div>
      <div className="my-4">
        <h6 className="my-3 fs-20">Capacity</h6>
        <p className="fs-16">
          <span className="fw-bold">Overnight Stay</span>: Accommodates up to
          8-10 guests comfortably.
        </p>
        {gold ? (
          ''
        ) : (
          <p className="fs-16">
            <span className="fw-bold">Dine with a Chef</span>: Up to 14 guests
            can enjoy this private culinary experience.
          </p>
        )}
        <p className="fs-16">
          <span className="fw-bold">Silent Disco</span>: Can host up to 30
          guests.
        </p>
        <p className="fs-16">
          <span className="fw-bold">Games Night</span>: Perfect for groups of up
          to 30 guests.
        </p>
      </div>
    </div>
  )
}
