export const isEmpty = (object: object | null): boolean =>
  object === null ||
  Object.keys(object).length <= 0 ||
  Object.values(object).every((x) => x === null || x === '');

export function separateFullName(fullName: string) {
  const nameParts = fullName.trim().split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');

  return {
    firstName,
    lastName,
  };
}

export const addPageToSessionStorage = (page: number) => {
  sessionStorage.setItem('propertyListPage', JSON.stringify(page));
};
export const getPageFromSessionStorage = () => {
  let pageString = sessionStorage.getItem('propertyListPage');
  if (pageString) {
    JSON.parse(pageString);
  } else {
    pageString = '1';
  }
  return pageString;
};

export const phoneNumberCheck =
  /(\+?\d{1,4}[\s.-]?[a-zA-Z0-9]{1,3}[\s.-]?[a-zA-Z0-9]{1,4}[\s.-]?[a-zA-Z0-9]{1,4}[\s.-]?[a-zA-Z0-9]{1,9}|(?:zero|one|two|three|four|five|six|seven|eight|nine)[\s.-]*)+/g;

export const phoneNumberRequestCheck =
  /\b(?:call\s+me|phone\s*number|mobile\s*number|(?:can|may|could|would|please|might|should)\s*(?:you\s+)?(?:give|send|provide|share|tell|text|show)\s*(?:me\s+)?(?:your\s+|a\s+)?phone\s*number|number|digits|contact\s*number|cell\s*number|mobile\s*number)\b/gi;

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset)
    .toISOString()
    .slice(0, 10);
};

export function numberWithCommas(x: string) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDateRange = (range: any) => {
  const formatToDateString = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  return `${formatToDateString(range.start)} - ${formatToDateString(
    range.end,
  )}`
}

export const formatCurrency = (value: any) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}