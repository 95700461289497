import { formatCurrency } from '@utils/currency'
import { formatDateRange } from '@utils/helpers'
import React, { useState } from 'react'

interface DateRange {
  start: Date
  end: Date
}

interface PeakPeriodProps {
  normalDays: number
  normalDateRanges: DateRange[]
  normalPrice: number
  peakDays: number
  peakDateRanges: DateRange[]
  peakPrice: number
  cautionFee?: string | number
  currency: string
  peakDaysTooltip?: string
}

const PeakPeriod: React.FC<PeakPeriodProps> = ({
  normalDays,
  normalDateRanges,
  normalPrice,
  peakDays,
  peakDateRanges,
  peakPrice,
  cautionFee,
  currency,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const totalPrice = normalPrice + peakPrice + (Number(cautionFee) || 0)

  return (
    <div>
      <div className="">
        <div className="d-flex justify-content-between text-center gap-1">
          <h6 className="text-gray-600 mb-1">Days</h6>
          <h6 className="text-gray-600 mb-1">Date ranges</h6>
          <h6 className="text-gray-600 mb-1">Total price</h6>
        </div>

        {normalDays > 0 && (
          <div className="d-flex justify-content-between gap-1 my-2 scrollable-range">
            <p className="fs-12 m-0">{normalDays} normal days</p>
            <div className="flex flex-col space-y-1">
              {normalDateRanges.map((range, index) => (
                <div key={`normal-${index}`} className="fs-12">
                  {formatDateRange(range)}
                </div>
              ))}
            </div>
            <p className="fs-12 m-0">
              {currency}
              {formatCurrency(normalPrice)}
            </p>
          </div>
        )}

        {peakDays > 0 && (
          <div className="d-flex justify-content-between gap-1 my-2 scrollable-range relative">
            <p className="fs-12 m-0 flex items-center">
              <span
                className="relative"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <i className="iconly-Info-Circle icli text-primary"></i>&nbsp;
              </span>
              {peakDays} peak days
              {showTooltip && (
                <div
                  className="absolute z-50 text-xs p-3 w-80 rounded shadow-lg bg-white"
                  style={{
                    top: '100%',
                    left: '0',
                    // whiteSpace: 'nowrap',
                    minHeight: '150px',
                  }}
                >
                  <p>
                    Disclaimer: Due to increased demand during the peak period
                    (December 01- January 15th), our shortlet prices experience
                    a 20% to 40% increase. We appreciate your understanding. For
                    any concerns or assistance, please contact our support team
                    at{' '}
                    <span className="text-primary">
                      <a
                        href="mailto:info@krent.space"
                        className="text-primary underline"
                      >
                        info@krent.space
                      </a>
                    </span>
                  </p>
                </div>
              )}
            </p>
            <div className="flex flex-col space-y-1">
              {peakDateRanges.map((range, index) => (
                <p key={`peak-${index}`} className="fs-12 m-0">
                  {formatDateRange(range)}
                </p>
              ))}
            </div>
            <p className="fs-12 m-0">
              {currency}
              {formatCurrency(peakPrice)}
            </p>
          </div>
        )}

        {cautionFee && (
          <div className="d-flex justify-content-between gap-1 my-2 scrollable-range">
            <p className="fs-12 m-0">Caution fee</p>
            <p className="fs-12 m-0">
              {currency}
              {cautionFee}
            </p>
          </div>
        )}

        <div className="d-flex justify-content-between my-2 scrollable-range">
          <h5>Total price</h5>
          <h5>
            {currency}
            {formatCurrency(totalPrice)}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default PeakPeriod
